<template>
  <!-- <div class="">
    <div class="" style="">
      <v-card tile flat style="background: #f1f1f1;">
        <v-card-title class="text-h4"><v-icon class="text-h4 mr-2">mdi-car</v-icon> Registration</v-card-title>
        <v-card-text>
          <div>Status</div>
          <div class="mb-6 text-h6 white black--text" style="border-radius: 5px; padding: 10px 15px;">{{ app.Status }}</div>
          <div>Vehicle</div>
          <div class="mb-6 text-h6">{{ app.yearMakeModel }}</div>
          <div>Created on</div>
          <div class="text-h6">{{ app.DateCreated | date }}</div>
        </v-card-text>
      </v-card>
    </div>
    <div class="ml-sm-5">
      <router-view></router-view>
    </div>
  </div> -->
  <!-- <div> -->
    <!-- <div class="d-flex align-center">
      <v-icon class="text-h2" style="width: 80px; padding: 0 15px;">mdi-car</v-icon>
      <div class="display-1 pointer" @click="navHome()">
        {{ app.yearMakeModel }}
        <div class="body-2">Created on {{ app.DateCreated | date }}</div>
      </div>
      <div class="text-h6 ml-auto success white--text" style="border-radius: 5px; padding: 10px 15px;">{{ app.Status }}</div>
    </div> -->
    <!-- <div> -->
      <router-view></router-view>
    <!-- </div>
  </div> -->
</template>

<script>
import store from '../../../store';
import VueRouter from '../../../router';

export default {
  components: {},
  props: {},
  created() {},
  computed: {
    app() {
      return this.$store.state.registration.app;
    },
    currentRouteName() {
      return this.$route.name;
    },
  },
  data: () => ({}),
  methods: {
    navHome() {
      const routeName = 'app.dashboard';
      if (this.currentRouteName !== routeName) {
        VueRouter.push({ name: routeName });
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    // console.log(">> from registration component: ", to.params.regId);
    const regId = to.params.regId;
    store.dispatch('billOfSale/clearBos');
    store
      .dispatch('registration/setActiveRecord', regId)
      .then(() => {
        next();
      })
      .catch(err => {
        store.commit('snackmsg/show', { text: err, color: 'error' });
        VueRouter.push({ name: 'app.dashboard' });
      });
  },
};
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>
